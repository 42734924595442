import './Kontakt.css'
// import salon from "../images/salon.jpg"
// import salon from
// import "../fonts/fonts.css"

function Kontakt() {

    return (
        <div style={{paddingTop: "5rem"}}>
        <div className="big-container-contact font-tiskani">
            {/*<div className="side-by-side">*/}
            {/*    <div className="centered-container font-tiskani-kontakt padding-15 contact-container">*/}
            {/*        <div className="font-italic padding-down font-large">*/}
            {/*            Kontakt za prodaju*/}
            {/*        </div>*/}
            {/*        <div className=" padding-down">*/}
            {/*            xxxxxxxxx*/}
            {/*        </div>*/}
            {/*        <div className=" padding-down">*/}
            {/*            Isključivo pozivi 9 - 17h*/}
            {/*        </div>*/}
            {/*        /!*<div className=" padding-down">*!/*/}
            {/*        /!*    Ulica Vjekoslava Klaića 12, 10000 Zagreb*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        /!*<div className=" padding-down">*!/*/}
            {/*        /!*    ninahrsakbeautyboutique@gmail.com*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        /!*<div className=" padding-down">*!/*/}
            {/*        /!*    ninahrsakbeautyboutiqueshop@gmail.com*!/*/}
            {/*        /!*</div>*!/*/}

            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="side-by-side img-container">*/}
            {/*    <img src="/images/stan.png" className="img-max" alt="Jesi"/>*/}

            {/*</div>*/}


            <div className="centered-container font-tiskani-kontakt padding-15 contact-container">
                        <div className="font-italic padding-down font-large">
                            Kontakt za prodaju
                        </div>
                        <div className=" padding-down">
                            091 6163 435
                        </div>
                        <div className=" padding-down">
                            Isključivo pozivi 9 - 17h
                        </div>
                        <div className=" padding-down">
                            projekt.sarajevska@gmail.com
                        </div>
                        {/*<div className=" padding-down">*/}
                        {/*    Ulica Vjekoslava Klaića 12, 10000 Zagreb*/}
                        {/*</div>*/}
                        {/*<div className=" padding-down">*/}
                        {/*    ninahrsakbeautyboutique@gmail.com*/}
                        {/*</div>*/}
                        {/*<div className=" padding-down">*/}
                        {/*    ninahrsakbeautyboutiqueshop@gmail.com*/}
                        {/*</div>*/}
            </div>


            {/*<div className="contact-container">*/}
            {/*    <div className="container bg-light text-dark lmi-container">*/}
            {/*        <h2 className="naslov">Kontakt za prodaju</h2>*/}
            {/*        <p className="fontstyle" style={{marginTop: '20px'}}>LMI Consult, obrt za usluge</p>*/}
            {/*        <p className="fontstyle">Ime i prezime: Ljerka Juroš</p>*/}
            {/*        <p className="fontstyle">Email adresa: lmi-consult@outlook.com</p>*/}
            {/*    </div>*/}

            {/*    <div className="container bg-light text-dark projekt trnsko-container">*/}
            {/*        <h2 className="naslov" style={{marginBottom: '20px'}}>Informacije o investitoru:</h2>*/}
            {/*        <div>PROJEKT SLOBOŠTINA d.o.o.</div>*/}
            {/*        <div >Ulica Pavla Štoosa 27, 10 000 ZAGREB, Hrvatska</div>*/}
            {/*        <div>IBAN:HR3923600001502869847 Zagrebačka banka d.d.</div>*/}
            {/*        <div>OIB:24700429146</div>*/}

            {/*        <div>Upisano u Trgovački sud u Zagrebu</div>*/}
            {/*        <div>MBS:081385052</div>*/}
            {/*        <div>Temeljni kapital 20.000,00 kn uplaćen u cijelosti.</div>*/}
            {/*        <div>Članovi uprave: Krunoslav Juroš dipl.ing.građ.</div>*/}
            {/*        <div>Transakcijski računi: </div>*/}
            {/*        <ul>*/}
            {/*        <li>HR1223600001102944862 Zagrebačka banka d.d.</li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="image-container">*/}
            {/*    <img src="images/Tabla.jpg" style={{maxHeight: '100%', maxWidth: '100%'}}/>*/}
            {/*</div>*/}
        </div>

            <div className="centered-container">
            <div className="big-container-contact font-tiskani">
                <div className="side-by-side">
                    <div className="font-tiskani-kontakt padding-15 contact-container">
                <h2 className="font-italic padding-down font-large" style={{marginBottom: '20px'}}>Informacije o investitoru:</h2>
                        <div className="padding-down">PROJEKT SARAJEVSKA d.o.o.</div>
                        <div className="padding-down" >Ulica Pavla Štoosa 27, 10 000 ZAGREB, Hrvatska</div>
                        {/*<div className="padding-down">IBAN:XXXXXXXXXXXXXXX Zagrebačka banka d.d.</div>*/}
                        <div className="padding-down">OIB:24021176847</div>
                        {/*<div>Upisano u Trgovački sud u Zagrebu</div>*/}
                        <div className="padding-down">MBS:081387187</div>
                        <div className="padding-down">EUID:HRSR.081387187</div>
                        <div className="padding-down">Temeljni kapital 3000,00 € uplaćen u cijelosti.</div>
                        {/*<div className="padding-down">PRAVNI OBLIK: društvo s ograničenom odgovornošću</div>*/}
                        {/*<div className="padding-down">PRETEŽITA DJELATNOST: 41.20 - Gradnja stambenih i nestambenih zgrada</div>*/}
                        <div className="padding-down">Osnivači / članovi društva:</div>
                        <ul>
                            <li  className="padding-down">Krunoslav Juroš, OIB: 15777113171
                                Zagreb, Ulica Pavla Štoosa 27 - član društva, direktor
                            </li>
                            <li className="padding-down">Marko Šteko,  OIB: 36855254748
                                Zagreb, Ulica Stjepana Babonića 108 - član društva, prokurist
                            </li>
                            <li className="padding-down">Nenad Pavković,  OIB: 53491082610
                                Zagreb, Bukovački vijenac 17 - član društva
                            </li>
                        </ul>
                        <div className="padding-down">Transakcijski računi: </div>
                        <ul>
                        <li>xxxxxxxxxxxxxxxxxxxx Zagrebačka banka d.d.</li>
                        </ul>
                    </div>
        </div>
            </div>
        </div>
        </div>
    );

}

export default Kontakt;