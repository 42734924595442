import React, {useEffect, useState} from 'react';
import './HeroSection.css';
import {useNavigate} from 'react-router-dom';
// import shop_cvijet from '../images/cvijet cropped.jpg'
// import creme_face from '../images/salon_lice cropped.jpg'
// import facial from '../images/natpis2 novi copy cropped.png'
// import facial_mala from '../images/naslovna manja.png'
// import "../fonts/fonts.css"


function HeroSection() {
    // const loggedIn = localStorage.getItem("loggedIn") === "true"
    let navigate = useNavigate();
    const [aspectRatio, setAspectRatio] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const ratio = window.innerHeight / window.innerWidth;
            setAspectRatio(ratio);
        };

        handleResize(); // Initial calculation

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // console.log(aspectRatio)



  return (
        <div className="hero-section">
            {/*<Navbar/>*/}
            <div className="image-container2">
                {/*<img src="/images/naslovne/Sarajevska_idejni%20projekt_03.jpg" className="" alt="photo"/>*/}
                <div id="image-container">
                    {aspectRatio >= 0.9 ? (
                        <img src="/images/naslovne/Sarajevska_idejni%20projekt_03.jpg" alt="Vertical" />
                    ) : (
                        <img src="/images/naslovne/Sarajevska_idejni%20projekt_03.jpg" alt="Horizontal" />
                    )}
                </div>
            </div>

            <div className="info-section">
                <h2>PROJEKT SARAJEVSKA - LUKSUZNI STANOVI I POSLOVNI PROSTORI U NOVOM ZAGREBU</h2>
                <p>O PROJEKTU Dobrodošli u Projekt Sarajevska, vaš novi dom u srcu Novog Zagreba. Ovaj jedinstveni projekt obuhvaća izgradnju moderno oblikovanih stambeno-poslovnih zgrada, smještenih uz koridor nove gradske avenije. Projekt je podijeljen u dvije faze kako bi zadovoljio sve vaše potrebe i želje.</p>
                <ul>
                    <li>FAZA 1 – Izgradnja podrumske etaže i istočne stambene lamele s 85 luksuznih stanova, katnosti Po+P+5.</li>
                    <li>FAZA 2 – Izgradnja zapadne poslovne lamele i pripadajućeg dijela podruma, katnosti Po+P+5.</li>
                </ul>
                <h3>MODERNA ARHITEKTURA I KVALITETNA IZVEDBA</h3>
                <p>Stambena lamela (FAZA 1) i poslovna lamela (FAZA 2) planirane su kao samostojeće građevine s kolnim i pješačkim pristupom omogućenim preko Sarajevske ulice. Svaka zgrada sastoji se od podruma, prizemlja i pet katova. Projektirane su s armiranobetonskim konstrukcijskim elementima, a njihov moderni dizajn s ravnim krovovima savršeno se uklapa u urbani krajolik.</p>
            </div>

            <div className="image-container2">
                <div className="image-container">
                    <img src="/images/naslovne/Sarajevska_idejni%20projekt_04.jpg" alt="Horizontal" />
                </div>
            </div>

            <div className="info-section">
                <h3>TERMOIZOLACIJA I FASADA</h3>
                <p>Pročelja stambenih i poslovnih zgrada te krovovi termoizolirani su prema najvišim standardima. Fasada stambenih zgrada obrađena je silikatnom žbukom u suvremenim tonovima i djelomično obložena dekorativnim materijalima. Vanjska PVC stolarija s prekinutim termičkim mostom i aluminijskim roletama s PU pjenom pruža optimalnu zaštitu od sunca. Poslovna zgrada ima modernu fasadu od aluminijskih i staklenih panela s aluminijskom stolarijom.</p>
                <h3>PARKIRALIŠTA I OKOLIŠ</h3>
                <p>Parkirališni prostor za stanare i korisnike poslovnih prostora osiguran je u podzemnoj garaži, natkrivenim parkirnim mjestima u prizemlju stambene zgrade te parkirnim mjestima oko objekata. Okoliš zgrada bit će hortikulturno uređen s kvalitetnim zelenilom, a pješačke i kolne površine bit će popločene betonskim opločnicima ili asfaltirane.</p>
            </div>

            <div className="image-container2">
                <div className="image-container">
                    <img src="/images/naslovne/Sarajevska_idejni%20projekt_F01-05.jpg" alt="Horizontal" />
                </div>
            </div>

            <div className="info-section">
                <h3>IDEALNA LOKACIJA</h3>
                <p>Projekt Sarajevska smješten je na istočnom dijelu Novog Zagreba, južno od rijeke Save. Ova lokacija omogućuje brz i jednostavan pristup centralnim dijelovima grada, bilo javnim prijevozom ili osobnim vozilom. Kvart je izvrsno povezan mrežom javnog prijevoza, s redovitim autobusnim i tramvajskim linijama koje omogućuju jednostavan dolazak do svih dijelova Zagreba.</p>
                <h3>OBITELJSKI PRIKLADNO</h3>
                <p>U neposrednoj blizini nalaze se osnovne i srednje škole, kao i vrtići, pružajući obiteljima sve potrebno za kvalitetan obiteljski život. Trgovine, restorani, parkovi i ostali sadržaji nalaze se u blizini, čineći ovu lokaciju idealnom za urbani život s lako dostupnim svim potrebama.</p>
                <h3>PROMET, INFRASTRUKTURA I SADRŽAJI</h3>
                <p>Projekt Sarajevska nudi brojne prednosti:</p>
                <ul>
                    <li>Centralni položaj na južnom ulazu u grad Zagreb, uz Sarajevsku cestu koja spaja Most mladosti s obilaznicom grada.</li>
                    <li>Blizina javnog prijevoza (autobusne linije na 200 m, tramvajske linije na 1.700 m, a uskoro i tramvajska pruga neposredno uz objekat na 100 m).</li>
                    <li>Blizina obrazovnih ustanova (vrtić na 800 m, nova osnovna škola u izgradnji na 400 m, osnovna škola Dugave na 900 m, dvije gimnazije na 2.000 m).</li>
                    <li>Blizina zdravstvenih ustanova (Dom zdravlja Dugave na 400 m).</li>
                    <li>Trgovački sadržaji (Tržnica Utrina na 1.700 m, Super Konzum na 700 m, Buzin City, Bauhaus i Pevex na 2.000 m).</li>
                    <li>Rekreacijski sadržaji (Bazeni na Utrinama na 1.700 m, Bundek na 3.000 m, rijeka Sava na 1.500 m).</li>
                </ul>
            </div>

            <div className="image-container2">
                <div className="image-container">
                    <img src="/images/naslovne/Sarajevska_idejni%20projekt_F01-01.jpg" alt="Horizontal" />
                </div>
            </div>

            <div className="info-section">
                <h3>ZAŠTO ODABRATI PROJEKT SARAJEVSKA?</h3>
                <p>Projekt Sarajevska nudi savršeni spoj
                    urbanog života i udobnosti. Njegova izvrsna lokacija, moderni dizajn, kvalitetna
                    izgradnja i pažljivo osmišljeni sadržaji čine ga idealnim mjestom za stanovanje ili
                    poslovanje. Uživajte u luksuzu, funkcionalnosti i povezanosti koje vam pruža naš
                    projekt.</p>
            </div>

            <div className="content">
            <div className="webshop-treatment-container">
                <div className="treatment-container">
                    <img src="/images/naslovne/Sarajevska_idejni%20projekt_02_cropped.jpg"  className="webshop-photo" alt=""/>
                    <button id="hero-btn" type="button" className="font-tiskani-mali btn btn-primary margin-top centered-button"
                            onClick={() => navigate("/stanovi")}
                            // onClick={openPdf}
                    >
                        Stanovi
                    </button>
                </div>
                <div className="webshop-container">
                    {/*<img src={creme_face} className="webshop-photo" alt=""/>*/}
                    <img src={"/images/naslovne/hero-section-druga.jpg"} className="webshop-photo" alt=""/>
                    <button id="hero-btn" type="button" className="font-pro btn btn-primary margin-top centered-button"
                            onClick={() => navigate("/oprema-stanova")}>
                        Oprema i parking
                    </button>
                </div>
            </div>
        </div>
        </div>
  );
}

export default HeroSection;
