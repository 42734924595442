import './Oprema.css'

function Oprema() {
    return (
        <div className="container-oprema">

            <div className="kosugi text-container prvi-container-padding-up">
                <h2>Stanovi</h2>
                Naši stanovi dizajnirani su s pažnjom na funkcionalnost i
                udobnost. Jasna podjela javnog i intimnog dijela stana smanjuje potrebu za
                unutarnjim hodnicima, a prostori su optimalno iskorišteni. Veći trosobni i
                četverosobni stanovi imaju ulazni prostor s garderobom i gospodarskim WC-om,
                centralno smješten dnevni boravak s kuhinjom i blagovaonicom te izlaz na loggiu
                ili balkon. Spavaći trakt s odvojenom kupaonicom osigurava privatnost i
                udobnost.
            </div>
            <div className="">
                <div className="text-container">
                    <div className="kosugi">
                        <h2>Parking</h2>
                        Dostupna su garažna parkirna mjesta u podrumu i prizemlju objekta i "suhi ulaz" liftom u stambeni dio zgrade.
                        Također, dostupna su vanjska parkirna mjesta smještena oko objekta.
                    </div>
                    <div>
                        <button type="button" id="btn-parking" className="font-pro font-size-card font-add-to-basket btn btn-primary margin-top"
                                onClick={() => window.open("/images/parking/Podrum - garažna mjesta.pdf", "_blank")}
                            // style={{marginTop: "1rem"}}
                        >
                            Garaža u podrumu
                        </button>
                    </div>
                    <div>
                        <button type="button" id="btn-parking" className="font-pro font-size-card font-add-to-basket btn btn-primary margin-top"
                                onClick={() => window.open("/images/parking/Prizemlje - parkirna i garažna mjesta (1).pdf", "_blank")}
                            // style={{marginTop: "1rem"}}
                        >
                            Garaža u prizemlju i vanjsko parkiralište
                        </button>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="text-container">
                    <div className="kosugi">
                        <h2>Oprema stanova</h2>
                        <ul>
                            <li>vanjska stolarija akril s trostrukim staklima</li>
                            <li>grijanje i klimatizacija stanova dizalicama topline</li>
                            <ul>
                                <li>dvosobni stan: dizalica topline zrak-zrak</li>
                                <li>trosobni i četverosobni stan: dizalica topline zrak-voda sa spremnikom tople vode</li>
                            </ul>
                            <li>višeslojni gotovi hrastov parket</li>
                            <li>vrhunska keramika po izboru kupaca</li>
                            <li>vrhunska sanitarna oprema i mješalice</li>
                            <li>ugradbeni vodokotlići</li>
                            <li>protuprovalna vrata</li>
                            <li>„demit“ fasada debljine 12-15 cm sa završnim silikatnim premazom</li>
                            <li>unutarnje kamene i vanjske limene klupice</li>
                            <li>gres pod na terasama</li>
                            <li>kameni mramorni pod u ulazu i stubištu</li>
                            <li>2 dizala za po 13 osoba</li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        //     <div className="vanjski-container-slika">
        //         <div className="container-oprema-unutarnji kosugi">
        //             <h2>Oprema stanova</h2>
        //             <ul>
        //                 <li>vanjska stolarija akril s trostrukim staklima</li>
        //                 <li>grijanje i klimatizacija stanova dizalicama topline</li>
        //                 <ul>
        //                     <li>dvosobni stan: dizalica topline zrak-zrak</li>
        //                     <li>trosobni i četverosobni stan: dizalica topline zrak-voda sa spremnikom tople vode</li>
        //                 </ul>
        //                 <li>višeslojni gotovi hrastov parket</li>
        //                 <li>vrhunska keramika po izboru kupaca</li>
        //                 <li>vrhunska sanitarna oprema i mješalice</li>
        //                 <li>ugradbeni vodokotlići</li>
        //                 <li>protuprovalna vrata</li>
        //                 <li>„demit“ fasada debljine 12-15 cm sa završnim silikatnim premazom</li>
        //                 <li>unutarnje kamene i vanjske limene klupice</li>
        //                 <li>gres pod na terasama</li>
        //                 <li>kameni mramorni pod u ulazu i stubištu</li>
        //                 <li>2 dizala za po 13 osoba</li>
        //             </ul>
        //         </div>
        //         {/*<div className="container-slika">*/}
        //         {/*    <img src="/images/naslovne/Sarajevska_idejni%20projekt_F01-05.jpg" style={{maxWidth: '100%'}}/>*/}
        //         {/*</div>*/}
        //     </div>
        // </div>
    );
}

export default Oprema;