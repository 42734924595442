import React from 'react'
// import PropTypes from 'prop-types'
// import {Link} from "react-router-dom";
import './ProductList.css'
// import {useSelector} from "react-redux";

// kartica proizvoda

const StanKartica = ({ stan }) => {

    const openPdf = () => {
        const pdfUrl = `${process.env.PUBLIC_URL}/images/stanovi/${stan.floorN}.kat/${stan.pdfName}.pdf`; // Assuming the PDF is in the public directory
        window.open(pdfUrl);
    };

    console.log(stan)

    // const [isHoveredAdd, setIsHoveredAdd] = useState(false);

    // const divStyleAdd = {
    //     borderBottom: isHoveredAdd ? "1px dashed black" : "1px solid",
    //     borderRight: isHoveredAdd ? "1px dashed black" : "1px solid",
    //     borderLeft: isHoveredAdd ? "1px dashed black" : "none",
    //     borderTop: isHoveredAdd ? "1px dashed black" : "none",
    //     // transition: "border-color 0.3s ease",
    //     // padding: "3px",
    //     // margin: "5px",
    //     // animation: "moveDottedBorder 2s linear infinite",
    // };

    return (
        <div id="card" className="card background-white" style={{width: '18rem'}}>
            {/*<img className="card-img-top product-img" src="products/images/naslovna.jpg" alt="Card image cap"/>*/}
            <div className='cards__item__link'>
                <div className="figure" style={{position: 'relative'}}>
                    <img className="Sirv image-main .product-img card-img-top"
                         // src={"/images/products/" + stan.image.split(",")[0]} alt=""
                        src={"/images/stanovi_kartice/B"+stan.id+".png"}
                         alt="slika"
                    />
                    <div className="overlay-pic"></div>
                    {/*{stan.floorN !== 0 && (*/}
                    {/*    <div className="discount-label"> /!* Add a discount label *!/*/}
                    {/*        - {stan.discount}%*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <div className="hover-overlay"></div>
                    <img className="Sirv image-hover .product-img card-img-top"
                         // src={"/images/products/" + stan.image.split(",")[1]}
                         src="/images/logo/logo_crni.png"
                         alt=""/>
                </div>
            </div>
            <div id="card-body" className="card-body background-white">
                <div>
                    <div>
                        <button
                            className="font-pro font-size-card margin-bottom-0 margin-top text-btn">{"Stan B" + stan.id}</button>
                    </div>
                </div>
                <div className="flex-space-between background-white">
                    <div>
                        <div>
                            <button
                                className="font-pro font-size-card margin-bottom-0 margin-top text-btn">{stan.floorN === 0 ? "prizemlje" : stan.floorN + ". kat"}</button>
                        </div>
                    </div>
                </div>
                <p className="card-text font-pro font-size-card font-colour-grey margin-bottom-0">{stan.area + " m"}<sup>2</sup></p>
                {/*<button id="btn" type="button" className="font-tiskani-mali btn btn-primary margin-top"*/}
                {/*        onClick={onAddToCartClicked}>*/}
                {/*    Dodaj u košaricu*/}
                {/*</button>*/}
                {/*<div className="button-add">*/}
                    <button
                        id="btn"
                        type="button"
                        className={`font-pro font-size-card font-add-to-basket btn btn-primary margin-top`}
                        // disabled={!checkAvailable(stan.productId)}
                        // onMouseEnter={() => setIsHoveredAdd(true)}
                        // onMouseLeave={() => setIsHoveredAdd(false)}
                        // style={checkAvailable(stan.productId) ? divStyleAdd : {}}
                        onClick={openPdf}
                    >
                        {"Otvori pdf"}
                    </button>
                {/*</div>*/}
            </div>
        </div>

        // <div style={{ marginBottom: 20 }}>
        //     <Product
        //         title={product.title}
        //         price={product.price}
        //         quantity={product.inventory} />
        //     <button
        //         onClick={onAddToCartClicked}
        //         disabled={product.inventory > 0 ? '' : 'disabled'}>
        //         {product.inventory > 0 ? 'Add to cart' : 'Sold Out'}
        //     </button>
        // </div>
    )
}

export default StanKartica
