import './Parking.css'

function Parking() {
    return (
        <div className="container-oprema">
            <div className="garaza-container">
                <div className="kosugi">
                    Dostupna su garažna parkirna mjesta u podrumu i prizemlju objekta i "suhi ulaz" liftom u stambeni dio zgrade.
                    Također, dostupna su vanjska parkirna mjesta smještena oko objekta.
                </div>
                <div>
                    <button type="button" id="btn-parking" className="font-pro font-size-card font-add-to-basket btn btn-primary margin-top"
                            onClick={() => window.open("/images/parking/Podrum - garažna mjesta.pdf", "_blank")}
                            // style={{marginTop: "1rem"}}
                        >
                        Otvori PDF tlocrta garaže u podrumu
                    </button>
                </div>
                <div>
                    <button type="button" id="btn-parking" className="font-pro font-size-card font-add-to-basket btn btn-primary margin-top"
                            onClick={() => window.open("/images/parking/Prizemlje - parkirna i garažna mjesta (1).pdf", "_blank")}
                            // style={{marginTop: "1rem"}}

                    >
                        Otvori PDF tlocrta garaže u prizemlju i vanjskog parkirališta
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Parking;