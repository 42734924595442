import './ProductList.css';
import "./FilterSortComponent.css";
import "../../fonts/fonts.css";
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StanKartica from "./StanKartica";
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Stanovi = () => {
    const [stanovi, setStanovi] = useState([]);
    // const [slobodni, setSlobodni] = useState(2);
    // const [kat, setKat] = useState("Svi");
    // const [sobe, setSobe] = useState(0);

    const floors = [1, 2, 3, 4, 5];

    const floorObjects = floors.map(floor => ({
        id: floor,
        name: `${floor}. kat`
    }));



    const room = [2, 3, 4];

    const roomObjects = room.map(room => ({
        id: room,
        name: `${room}-sobni`
    }));

    const zauzetost = ['Zauzeti', 'Slobodni'];

    const zauzetostObjects = zauzetost.map((status, index) => ({
        id: index,
        name: status
    }));

    useEffect(() => {
        fetch('../stanovi.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(json => setStanovi(json));
    }, []);

    const [filtersVisible, setFiltersVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedFloors, setSelectedFloors] = useState([]);
    const [selectedZauzetost, setSelectedZauzetost] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]);

    const [priceFrom, setPriceFrom] = useState('');
    const [priceTo, setPriceTo] = useState('');
    const [sortOrder, setSortOrder] = useState('default');
    const [sortDisplayText, setSortDisplayText] = useState('Sortiranje');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    // const maxOptionWidth = '7rem';

    useEffect(() => {
        switch (sortOrder) {
            case 'price-asc':
                setSortDisplayText('Površina: niža prema višoj');
                break;
            case 'price-desc':
                setSortDisplayText('Površina: viša prema nižoj');
                break;
            default:
                setSortDisplayText('Sortiranje');
        }
    }, [sortOrder]);

    // const handleSearchChange = (e) => setSearchQuery(e.target.value);


    const handleFloorToggle = (floorId) => {
        if (selectedFloors.includes(floorId)) {
            setSelectedFloors(selectedFloors.filter(flId => flId !== floorId));
        } else {
            setSelectedFloors([...selectedFloors, floorId]);
        }
    };

    const handleZauzetostToggle = (zauzetostId) => {
        if (selectedZauzetost.includes(zauzetostId)) {
            setSelectedZauzetost(selectedZauzetost.filter(flId => flId !== zauzetostId));
        } else {
            setSelectedZauzetost([...selectedZauzetost, zauzetostId]);
        }
    };


    const handleRoomToggle = (roomId) => {
        if (selectedRooms.includes(roomId)) {
            setSelectedRooms(selectedRooms.filter(rId => rId !== roomId));
        } else {
            setSelectedRooms([...selectedRooms, roomId]);
        }
    };

    const handlePriceFromChange = (e) => setPriceFrom(e.target.value);
    const handlePriceToChange = (e) => setPriceTo(e.target.value);
    const handleSortOrderChange = (value) => {
        setSortOrder(value);
        setDropdownVisible(false);
    };

    const countAppliedFilters = () => {
        let count = 0;
        if (searchQuery) count++;

        if (selectedFloors.length > 0) count++;
        if (selectedRooms.length > 0) count++;
        if (selectedZauzetost.length > 0) count++;


        if (priceFrom || priceTo) count++;
        return count;
    };

    const appliedFiltersCount = countAppliedFilters();

    const applyFilters = () => {
        setFiltersVisible(false);
    };

    const cancelFilters = () => {
        setSearchQuery('');

        setSelectedRooms([]);
        setSelectedFloors([]);
        setSelectedZauzetost([])

        setPriceFrom('');
        setPriceTo('');
        setFiltersVisible(false);
    };

    const filteredAndSortedStanovi = stanovi
        // .filter(stan => (slobodni === 2 || stan.free === slobodni))
        // .filter(stan => (kat === "Svi" || stan.floorN === kat))
        // .filter(stan => (sobe === 0 || stan.rooms === sobe))

        .filter(stan => (selectedFloors.length === 0 || selectedFloors.includes(stan.floorN)))
        .filter(stan => (selectedRooms.length === 0 || selectedRooms.includes(stan.rooms)))
        .filter(stan => (selectedZauzetost.length === 0 ||
            selectedZauzetost.includes(2) ||
            selectedZauzetost.includes(stan.free)))
        .filter(stan => (priceFrom === '' || stan.area >= priceFrom))
        .filter(stan => (priceTo === '' || stan.area <= priceTo))

        // .filter(stan => (searchQuery === '' || stan.name.toLowerCase().includes(searchQuery.toLowerCase())))
        .sort((a, b) => {
            if (sortOrder === 'price-asc') return a.area - b.area;
            if (sortOrder === 'price-desc') return b.area - a.area;
            return 0;
        });

    // const resetirajGumbe = () => {
    //     setSobe(0);
    //     setSlobodni(2);
    //     setKat("Svi");
    // };


    return (
        <div style={{ paddingTop: "7rem", backgroundColor: "white" }}>
            <div className="filter-sort-wrapper">
                <div className="filter-sort-container">
                    <button className="filter-menu-btn kosugi" onClick={() => setFiltersVisible(!filtersVisible)}>
                        Filter {appliedFiltersCount > 0 ? `(${appliedFiltersCount})` : ''}
                    </button>
                    {appliedFiltersCount > 0 &&
                        <button
                            className="close-button-filter"
                            type="button"
                            onClick={cancelFilters}
                            style={{color:"black"}}
                        >×</button>}
                    <div className="sort-container-wrapper">
                        <button className="sort-button kosugi" onClick={() => setDropdownVisible(!dropdownVisible)}  style={{color:"black"}}>
                            {sortDisplayText}
                        </button>
                        {dropdownVisible && (
                            <div className="sort-dropdown">
                                <div className="kosugi" onClick={() => handleSortOrderChange('default')}>Preporučeno</div>
                                <div className="kosugi" onClick={() => handleSortOrderChange('price-asc')}>Površina: niža prema višoj</div>
                                <div className="kosugi" onClick={() => handleSortOrderChange('price-desc')}>Površina: viša prema nižoj</div>
                            </div>
                        )}
                    </div>
                    {filtersVisible && (
                        <>
                            <div className="overlay" onClick={() => setFiltersVisible(false)}></div>
                            <div className="filter-modal">
                                <div className="modal-header" id="modal-header" style={{ borderBottom: "1px solid lightgray" }}>
                                    <div className="modal-headline">Filteri</div>
                                    <button
                                        className="close-button"
                                        type="button"
                                        onClick={() => setFiltersVisible(false)}
                                        style={{color:"black"}}
                                    >×</button>
                                </div>
                                <div className="filter-options">
                                    <div>
                                        <div className="category-headline kosugi">
                                            Kat
                                        </div>
                                        <div className="categories">
                                            {floorObjects.map(floor => (
                                                <button
                                                    style={{fontFamily: "Kosugi, sans-serif", color:"black"}}
                                                    key={floor.id}
                                                    className={`category-button kat-button kosugi ${selectedFloors.includes(floor.id) && "active"}`}
                                                    onClick={() => handleFloorToggle(floor.id)}
                                                >
                                                    {floor.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-headline kosugi">
                                            Broj soba
                                        </div>
                                        <div className="categories">
                                            {roomObjects.map(room => (
                                                <button
                                                    key={room.id}
                                                    style={{fontFamily: "Kosugi, sans-serif", color:"black"}}
                                                    className={`category-button kosugi ${selectedRooms.includes(room.id) && "active"}`}
                                                    onClick={() => handleRoomToggle(room.id)}
                                                >
                                                    {room.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="category-headline kosugi">
                                            Površina
                                        </div>
                                        <div className="price-range">
                                            <input
                                                style={{fontFamily: "Kosugi, sans-serif"}}
                                                type="number"
                                                placeholder="Od"
                                                className="price-input kosugi"
                                                value={priceFrom}
                                                onChange={handlePriceFromChange}
                                            />
                                            <input
                                                style={{fontFamily: "Kosugi, sans-serif"}}
                                                type="number"
                                                placeholder="Do"
                                                className="price-input"
                                                value={priceTo}
                                                onChange={handlePriceToChange}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="category-headline kosugi">
                                            Zauzetost
                                        </div>
                                        <div className="categories">
                                            {zauzetostObjects.map(zauzetost => (
                                                <button
                                                    key={zauzetost.id}
                                                    style={{fontFamily: "Kosugi, sans-serif", color:"black"}}
                                                    className={`category-button kosugi ${selectedZauzetost.includes(zauzetost.id) && "active"}`}
                                                    onClick={() => handleZauzetostToggle(zauzetost.id)}
                                                >
                                                    {zauzetost.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>


                                    {/*<div>*/}
                                    {/*    <div className="category-headline">*/}
                                    {/*        Tražilica*/}
                                    {/*    </div>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        placeholder="Pretraži..."*/}
                                    {/*        className="search-input"*/}
                                    {/*        value={searchQuery}*/}
                                    {/*        onChange={handleSearchChange}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="filter-buttons">
                                        <button className="apply-btn kosugi" id="apply-cancel-btn" onClick={applyFilters}>Primjeni {appliedFiltersCount > 0 ? `(${appliedFiltersCount})` : ''}</button>
                                        <button className="cancel-btn kosugi" id="apply-cancel-btn" onClick={cancelFilters}>Otkaži</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>


            <div className="cards padding background-white">
                <div className='cards__container background-white'>
                    <div className='cards__wrapper background-white'>
                        <div className='grid-container background-white'>
                            {filteredAndSortedStanovi.map(stan => (
                                <StanKartica key={stan.productId} stan={stan} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="cards padding background-white">*/}
            {/*    <div className='cards__container background-white'>*/}
            {/*        <div className='cards__wrapper background-white'>*/}
            {/*            <div className='grid-container background-white'>*/}
            {/*                {filteredAndSortedStanovi.map(stan => (*/}
            {/*                    <StanKartica key={stan.productId} stan={stan} />*/}
            {/*                ))}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};


Stanovi.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: PropTypes.number.isRequired,
            categoryName: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Stanovi;
