import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './Navbar.css';
import "../fonts/fonts.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';



function Navbar2({ cartItemCount }) {
    // const navigate = useNavigate();
    const location = useLocation();

    const [scrolled, setScrolled] = useState(false);
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showBanner] = useState(location.pathname.startsWith('/shop') || location.pathname.startsWith('/cart'));
    // const [showLogo, setShowLogo] = useState(false);

    // useEffect(() => {
    //     if (!navbarOpen) {
    //         const timeout = setTimeout(() => {
    //             setShowLogo(true);
    //         }, 500); // Adjust this timeout based on your animation duration
    //
    //         return () => clearTimeout(timeout);
    //     } else {
    //         setShowLogo(false);
    //     }
    // }, [navbarOpen]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 200) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavLinkClick = () => {
        setNavbarOpen(false);
    }

    const getNavLinkClass = () => {
        console.log("f nav")
        return scrolled || location.pathname !== '/' ? 'nav-link-dark' : 'nav-link-light';
    };

    return (
        <>
            <div className={`navbar${scrolled ? ' scrolled' : ''}`} id="navbar" style={{ zIndex: "100", marginTop: showBanner ? '1.8rem' : '', backgroundColor: showBanner ? "white" : "" }}>
                {/*{navbarOpen && (*/}
                {/*    <div className="navbar-bg-big" style={{ marginTop: showBanner ? '3rem' : '', backgroundColor: "pink" }}></div>*/}
                {/*)}*/}

                <nav className={`navbar-bg${scrolled ? ' scrolled' : ''}${navbarOpen ? ' navbar-opened-bg' : ''} navbar-expand-lg navbar-light font-pro grandparent`}>
                    {/*{(showLogo || windowWidth > 991) && (*/}

                    <Link to={"/"}>
                        <div className="logo-div"
                             // style={{marginTop: "1rem"}}
                        >
                            {
                                (scrolled || navbarOpen || location.pathname !== "/") ?
                                <img
                                    src={"/images/logo/logo_crni_cropped.png"}
                                    className="logo-img" alt="Logo"/>
                            :
                                <img
                                    src={"/images/logo/logo_moshed_cropped.png"}
                                    className="logo-img" alt="Logo"/>

                            }
                        </div>
                    {/*)}*/}
                    </Link>

                    {(windowWidth > 991) && (
                    <div className={`collapse navbar-collapse justify-content-left centered parent font-navbar ${navbarOpen ? 'show' : ''}`} id="collapsibleNavbar">
                        <ul className="navbar-nav center">
                            <li className="nav-item active nav-item-not-dropdown">
                                <Link className="nav-link" id={getNavLinkClass()} to="/" onClick={handleNavLinkClick}>Početna <span className="sr-only"></span></Link>
                            </li>
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className="nav-link" id={getNavLinkClass()} to="/stanovi" onClick={handleNavLinkClick}>Stanovi</Link>
                            </li>
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className={`nav-link`} id={getNavLinkClass()} to="/oprema-stanova" onClick={handleNavLinkClick}>Oprema i parking</Link>
                            </li>
                            {/*<li className="nav-item nav-item-not-dropdown">*/}
                            {/*    <Link className={`nav-link`} id={getNavLinkClass()} to="/parking" onClick={handleNavLinkClick}>Parking</Link>*/}
                            {/*</li>*/}
                            <li className="nav-item nav-item-not-dropdown">
                                <Link className="nav-link" id={getNavLinkClass()} to="/kontakt" onClick={handleNavLinkClick}>Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                    )}
                    <button className="navbar-toggler" type="button" id="navbar-toggler" onClick={handleToggle}>
                        {navbarOpen ? <FontAwesomeIcon icon={faTimes} className="font-toggler-close"/> :
                        <span className="font-toggler">izbornik</span>}
                        {/*<FontAwesomeIcon icon={navbarOpen ? faTimes : faBars} className="font-toggler" />*/}
                    </button>
                </nav>

            </div>
            {(windowWidth < 991) && navbarOpen && (
                <div className={`${navbarOpen ? 'show' : ''} navbar-opened navbar-opened ${scrolled ? 'scrolled' : ''}`} id="">
                    <ul className="navbar-nav navbar-list-opened">
                        <li className="nav-item active nav-item-not-dropdown nav-item-not-dropdown-first">
                            <Link className="nav-link" id="nav-link-opened" to="/" onClick={handleNavLinkClick}>Početna <span className="sr-only"></span></Link>
                        </li>
                        <li className="nav-item nav-item-not-dropdown">
                            <Link className="nav-link" id="nav-link-opened" to="/stanovi" onClick={handleNavLinkClick}>Stanovi</Link>
                        </li>
                        <li className="nav-item nav-item-not-dropdown">
                            <Link className="nav-link" id="nav-link-opened" to="/oprema-stanova" onClick={handleNavLinkClick}>Oprema i parking</Link>
                        </li>
                        {/*<li className="nav-item nav-item-not-dropdown">*/}
                        {/*    <Link className="nav-link" id="nav-link-opened" to="/parking" onClick={handleNavLinkClick}>Parking</Link>*/}
                        {/*</li>*/}
                        <li className="nav-item nav-item-not-dropdown">
                            <Link className="nav-link" id="nav-link-opened" to="/kontakt" onClick={handleNavLinkClick}>Kontakt</Link>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

export default Navbar2;
